import { SET_ALERT, REMOVE_ALERT } from "../actions";

const initialState = {
  showAlert: false,
  message: "",
  alertType: "ERROR",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        showAlert: true,
        message: action.payload.message,
        alertType: action.payload.type,
      };

    case REMOVE_ALERT:
      return {
        ...state,
        showAlert: false,
        message: "",
        alertType: "ERROR",
      };
    default:
      return { ...state };
  }
};