import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";

const app = (
    // <React.StrictMode>
        <Provider store={configureStore()}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    // </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

