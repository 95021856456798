import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// MetisMenu
import MetisMenu from "metismenujs";
import { DashboardRoutes } from "../../pages/Dashboard/Constants/routes";

const SidebarContent = ({ location, role }) => {

    useEffect(() => {
        initMenu();
    });

    const initMenu = () => {
        new MetisMenu("#side-menu");
        let matchingMenuItem = null;
        let ul = document.getElementById("side-menu");
        let items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];

            } else {
                items[i].classList.remove("active");
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }

    const activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };
    return (
        <>
            <div id="sidebar-menu">

                <ul className="metismenu list-unstyled" id="side-menu">

                    {DashboardRoutes && DashboardRoutes.map((item) => { // eslint-disable-line
                        if (item.visibleTo.includes(role)) {
                            return (
                                <li key={item.id}>
                                    <Link to={item.routePath} className="waves-effect">
                                        <i className={item.icon}></i>
                                        <span className="ml-1">{item.name}</span>
                                    </Link>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>

        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    role: auth.role
});

export default connect(mapStateToProps, null)(withRouter(SidebarContent));
