import React from "react";

//Simple bar
import SimpleBar from "simplebar-react";
import SidebarContent from "./SideBarContent";


const Sidebar = ({ type }) => {

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    {type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent />
                        </SimpleBar>
                    ) : <SidebarContent />}
                </div>

            </div>
        </React.Fragment>
    );
};

export default Sidebar;
