import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userLogout } from '../../redux/auth/actions';

const Logout = ({ userLogout }) => {

    const history = useHistory();
    useEffect(() => {
        userLogout("", history);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <h1>Logging Off...</h1>
        </>
    );
};

export default connect(null, { userLogout })(Logout);
