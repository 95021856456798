import React from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";
import AlertService from "./helpers/AlertService";

const App = () => {

	return (
		<React.Fragment>
			<Router>
				<AlertService>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={VerticalLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}

					</Switch>
				</AlertService>
			</Router>
		</React.Fragment>
	);
}

export default App;
